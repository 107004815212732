import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../ui-kit/LoadingSpinner';
import { CenteredContainer } from '../ui-kit/CenteredContainer';

export const SuspenseFallback: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredContainer className="vh-100 justify-content-center">
      <LoadingSpinner spinner="circle" size="large" />
      <span>{t('loading-page')}...</span>
    </CenteredContainer>
  );
};
