import { IExecutorMessage } from './core/types';
import { IExtendedTask } from './core/models/task';

export enum TaskState {
  NONE,
  EXECUTING,
  FAILED,
  DONE,
}

export interface ITaskWithStatus extends IExtendedTask {
  isScriptFetched: boolean;
  status: {
    state: TaskState;
    error?: string;
  };
}

export interface IExecutorAttachedMessage {
  executorId: number;
  message: IExecutorMessage;
}

export type ExecutorsMessages = {
  [id: string]: IExecutorMessage[];
};

export interface IExecutorComponent {
  executorId: number;
  component: JSX.Element;
}

export interface IIntegrationState {
  selectedTaskId: number | null;
  tasks: ITaskWithStatus[];
  executorsMessages: ExecutorsMessages;
}
