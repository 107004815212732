import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BaseRoutesPath } from '../../../../routes/base-paths';
import { Button } from '../../../../ui-kit/Button';
import { Result } from '../../../../ui-kit/Result';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('http.not-found')}
      extra={
        <Link to={BaseRoutesPath.HOME}>
          <Button type="primary">{t('return-home')}</Button>
        </Link>
      }
    />
  );
};
