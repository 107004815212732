import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/app';
import AxiosInstance from '../../../../../../axios-instance';
import { IMeta } from '../../../../../../deps/frontend-modules/types';
import { AuthHelper } from '../../../../../../store/auth/helpers';
import { IntegrationRunnerService } from '../../services/runner-service';
import { IExecutorMessage } from '../../core/types';

const handlers = [require('../../../../../../deps/frontend-modules/gui/handlers')];

export const GuiContainerComponent: React.FC = () => {
  const executorsMessages = useSelector((state: RootState) => state.integration.executorsMessages);

  const getTopComponents = (): JSX.Element[] => {
    const components: JSX.Element[] = [];
    const executorIds = Object.keys(executorsMessages);
    for (let i = 0; i < executorIds.length; i++) {
      const executorId = executorIds[i];
      const commands = executorsMessages[executorId];
      for (let j = 0; j < commands.length; j++) {
        const command = commands[j];
        const executor = IntegrationRunnerService.getExecutor(executorId);
        if (!executor) {
          continue;
        }

        for (let k = 0; k < handlers.length; k++) {
          const component = handlers[k].default(command, (msg: IExecutorMessage) => executor.send(msg), {
            axios: AxiosInstance,
            user: AuthHelper.getUserInfo(),
            task: {
              id: executor.runOptions.task.id,
            },
          } as IMeta);

          if (component && executor) {
            components.push(<React.Fragment key={executor.id + j}>{component}</React.Fragment>);
          }
        }
      }
    }
    return components;
  };

  return <React.Fragment>{getTopComponents()}</React.Fragment>;
};
