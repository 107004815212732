import { initializeIntegrationFeature } from './user/integration/initializer';
import { IUserMinimalInfo } from '../packages/auth/models/user';
import {
  unregisterAllAdminSidebarElements,
  unregisterAllDefaultSidebarElements,
} from '../packages/core/components/Sidebar/registry';
import { unregisterAllRoutes } from '../routes/registry';
import { initializeAttributesConstructor } from './admin/attributes-constructor/initializer';
import { AnyAction, Store } from '@reduxjs/toolkit';

export interface IInitializeData {
  user: IUserMinimalInfo | null;
  store: Store<unknown, AnyAction>;
}

export async function initializeUserFeatures({ user, store }: IInitializeData): Promise<void> {
  unregisterAllDefaultSidebarElements();
  await initializeIntegrationFeature(store, user);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function initializeAdminFeatures(data: IInitializeData): Promise<void> {
  unregisterAllAdminSidebarElements();

  initializeAttributesConstructor();
}

export async function uninitializeFeatures(): Promise<void> {
  unregisterAllDefaultSidebarElements();
  unregisterAllAdminSidebarElements();
  unregisterAllRoutes();
}
