import { AnyAction, createAction, ThunkDispatch } from '@reduxjs/toolkit';
import { IUserMinimalInfo } from '../../packages/auth/models/user';
import { getApiErrorMessage } from '../../packages/core/error-handler';
import { BaseRoutesPath } from '../../routes/base-paths';
import { AuthService } from '../../packages/auth/services/auth-service';
import { AppThunk, AppDispatch, RootState, AppThunkDispatch } from '../app';
import { uninitializeFeatures } from '../../features/initializer';
// import { push } from 'react-router-redux';
import { push } from '@lagunovsky/redux-react-router';

export const setAuthenticated = createAction<boolean>('@@auth/setAuthenticated');
export const setAccessToken = createAction<string>('@@auth/setAccessToken');
export const setUser = createAction<IUserMinimalInfo>('@@auth/setUser');
export const setAuthLoading = createAction<boolean>('@@auth/setLoading');
export const setAuthError = createAction<string>('@@auth/setError');
export const clearAuthError = createAction('@@auth/clearError');

export function loginThunk(login: string, password: string, redirectTo: string): AppThunk<void> {
  return async function (dispatch: AppDispatch): Promise<void> {
    dispatch(clearAuthError());
    dispatch(setAuthLoading(true));
    try {
      const response = await AuthService.signIn(login, password);
      dispatch(setAccessToken(response.accessToken));
      dispatch(setUser(response.user));
      dispatch(setAuthenticated(true));
      dispatch(push(redirectTo));
    } catch (ex: any) {
      dispatch(setAuthError(getApiErrorMessage(ex, 'Ошибка аутентификации')));
    }
    dispatch(setAuthLoading(false));
  };
}

export function clearAuthData() {
  return function (dispatch: AppDispatch): void {
    AuthService.signOut();
    dispatch(setAuthenticated(false));
  };
}

// export function logoutThunk() {
//   return async function (dispatch: AppDispatch): Promise<void> {
//     uninitializeFeatures();
//     (dispatch as ThunkDispatch<RootState, void, AnyAction>)(clearAuthData());
//     dispatch(push(BaseRoutesPath.LOGIN));
//   };
// }

export function logoutThunk() {
  return async function (dispatch: AppThunkDispatch): Promise<void> {
    uninitializeFeatures();
    (dispatch as ThunkDispatch<RootState, void, AnyAction>)(clearAuthData());
    dispatch(push(BaseRoutesPath.LOGIN));
  };
}
