import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import cn from 'classnames';
import { INumberInputProps } from '.';
import { TextInput } from '../TextInput';
import { InputRef } from 'antd';

const DOUBLE_REGEX = /^-?(0|([1-9]\d*))(\.(?<=\.)\d*|)$/;
// const DOUBLE_REGEX = /^-?((0{1})|[1-9]\d*)([.,]\d+)?/;
// const DOUBLE_REGEX = /^-?\d*(\.\d*)?$/;

function textToDouble(text: string | null | undefined, onSuccess?: (value?: string) => void) {
  if (!onSuccess) {
    return;
  }

  if (!text) {
    onSuccess(undefined);
  } else if (DOUBLE_REGEX.test(text)) {
    onSuccess(text);
  }
}

const DoubleInputForwardRef: ForwardRefRenderFunction<InputRef, INumberInputProps> = (
  { className, value, onChange, onBlur, ...restProps },
  ref
) => {
  const handleChange = (text?: string | null) => {
    textToDouble(text, onChange);
    // onChange?.(text ? +text : undefined);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    textToDouble(text, onBlur);
    // onBlur?.(text ? +text : undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };

  return (
    <TextInput
      ref={ref}
      className={cn('double-input', className)}
      type="number"
      value={value?.toString() ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...restProps}
    />
  );
};

export const DoubleInput = forwardRef<InputRef, INumberInputProps>(DoubleInputForwardRef);
