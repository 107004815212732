import React, { forwardRef, ForwardRefRenderFunction, Ref, useEffect, useState } from 'react';
import Input, { InputRef } from 'antd/lib/input/Input';
import PasswordInput from 'antd/lib/input/Password';

type HtmlInputProps = React.InputHTMLAttributes<HTMLInputElement>;
type HtmlInputPropsWithoutOnChangeAndValue = Omit<HtmlInputProps, 'onChange' | 'value' | 'size' | 'prefix' | 'suffix'>;

interface ITextInputProps extends HtmlInputPropsWithoutOnChangeAndValue {
  autoFocus?: boolean;
  type?: 'password' | 'number';
  value?: string | null;
  size?: 'small' | 'middle' | 'large';
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onChange?: (value?: string) => void;
  onUpdate?: (value?: string) => void;
}

const TextInputForwardRef: ForwardRefRenderFunction<InputRef, ITextInputProps> = (
  { autoComplete, type, value, onChange, onUpdate, ...restProps },
  ref
) => {
  const [updatedValue, setUpdatedValue] = useState<string>();

  useEffect(() => {
    setUpdatedValue((value || restProps.defaultValue)?.toString());
  }, [value, restProps.defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.currentTarget.value || undefined;
    onChange?.(newValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const newValue = e.currentTarget.value || undefined;
    if (onUpdate && newValue !== updatedValue) {
      setUpdatedValue(newValue);
      onUpdate(newValue);
    }
    restProps.onBlur?.(e);
  };

  if (type === 'password') {
    return (
      <PasswordInput
        ref={ref}
        type="text"
        value={value != null ? value : undefined}
        onBlur={handleBlur}
        onChange={handleChange}
        {...restProps}
      />
    );
  }

  return (
    <Input
      ref={ref}
      autoComplete={autoComplete ?? 'off'}
      type={type}
      value={value != null ? value : undefined}
      onBlur={handleBlur}
      onChange={handleChange}
      onWheel={(e) => e.currentTarget.blur()}
      {...restProps}
    />
  );
};

export const TextInput = forwardRef<InputRef, ITextInputProps>(TextInputForwardRef);
