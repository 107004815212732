import { History } from 'history';
import authentication from './auth/reducers';
import { combineReducers } from '@reduxjs/toolkit';
import { statusReducer } from './status/reducer';
import { integrationReducer } from '../features/user/integration/tasks/store/reducer';
// import { routerReducer } from 'react-router-redux';
// import { connectRouter } from 'connected-react-router';
import { createRouterReducer } from '@lagunovsky/redux-react-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createRootReducer(history: History) {
  return combineReducers({
    router: createRouterReducer(history),
    authentication,
    status: statusReducer,
    integration: integrationReducer,
  });
}
