import { PayloadAction } from '@reduxjs/toolkit';
import {
  getActionName,
  isRequestType,
  isSuccessType,
  isFailureType,
  RequestType,
  IFailureActionPayload,
} from './helpers';

interface IStatus {
  isFetching?: boolean;
  isAdding?: boolean;
  isUpdating?: boolean;
  isDeleting?: boolean;
  [requestType: number]: {
    success?: boolean;
    error?: string;
  };
}

export interface IRequestStatusState {
  [actionName: string]: IStatus;
}

function getPropertyNameFromRequestType(type: RequestType): keyof IStatus {
  switch (type) {
    case RequestType.FETCH:
      return 'isFetching';
    case RequestType.ADD:
      return 'isAdding';
    case RequestType.UPDATE:
      return 'isUpdating';
    case RequestType.PARTIAL_UPDATE:
      return 'isUpdating';
    case RequestType.DELETE:
      return 'isDeleting';
  }
}

export const statusReducer = (
  state: IRequestStatusState = {},
  action: PayloadAction<RequestType | IFailureActionPayload, string>
): IRequestStatusState => {
  const { type, payload } = action;
  const actionName = getActionName(type);
  const statePropertyName = getPropertyNameFromRequestType(
    isFailureType(type) ? (payload as IFailureActionPayload).requestType : (payload as RequestType)
  );

  if (!actionName) {
    return state;
  }

  if (isRequestType(type)) {
    return {
      ...state,
      [actionName]: {
        ...(state[actionName] || {}),
        [statePropertyName]: true,
        [payload as RequestType]: {
          success: false,
        },
      },
    };
  }

  if (isSuccessType(type)) {
    return {
      ...state,
      [actionName]: {
        ...(state[actionName] || {}),
        [statePropertyName]: false,
        [payload as RequestType]: {
          success: true,
        },
      },
    };
  }

  if (isFailureType(type)) {
    return {
      ...state,
      [actionName]: {
        ...(state[actionName] || {}),
        [statePropertyName]: false,
        [(payload as IFailureActionPayload).requestType]: {
          success: false,
          error: (payload as IFailureActionPayload).error,
        },
      },
    };
  }

  return state;
};
