import React from 'react';
import AntResult from 'antd/lib/result';

export declare type ExceptionStatusType = 403 | 404 | 500 | '403' | '404' | '500';
export declare type ResultStatusType = ExceptionStatusType | 'success' | 'error' | 'info' | 'warning';

interface IResultProps {
  icon?: React.ReactNode;
  status?: ResultStatusType;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Result: React.FC<IResultProps> = AntResult;
