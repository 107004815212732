import React from 'react';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { lazy } from 'react';
import { registerDefaultSidebarElement } from '../../../packages/core/components/Sidebar/registry';
import { registerRoute } from '../../../routes/registry';
import { IntegrationRoutePaths } from './route-paths';
import { IntegrationRunnerService } from './tasks/services/runner-service';
import { attachToStore } from './tasks/ws';
import { IUserMinimalInfo } from '../../../packages/auth/models/user';
import { ISidebarElement } from '../../../packages/core/components/Sidebar/Sidebar';
import { RightsService } from '../../../packages/auth/services/rights-service';
import { PermissionResource } from '../../../packages/auth/permission-resource';
import { PermissionRwAccess, PermissionSimpleAccess } from '../../../packages/auth/permission';

const INTEGRATION_FEATURES: FeatureIds[] = ['integration-tasks', 'integration-scheduler', 'integration-statistic'];

const IntegrationTasksPage = lazy(() => import('./tasks'));
const IntegrationSchedulerPage = lazy(() => import('./scheduler'));
const IntegrationStatisticsPage = lazy(() => import('./statistics'));

export async function initializeIntegrationFeature(
  store: Store<unknown, AnyAction>,
  user?: IUserMinimalInfo | null
): Promise<void> {
  if (!window.ENABLED_FEATURES.some((feature) => INTEGRATION_FEATURES.includes(feature))) {
    return;
  }

  const sidebarElements: ISidebarElement[] = [];

  if (
    window.ENABLED_FEATURES.includes('integration-tasks') &&
    user &&
    (await RightsService.hasPermission(
      user.id,
      PermissionResource.INTEGRATION_TASK_EDITOR,
      PermissionSimpleAccess.PERMIT
    ))
  ) {
    sidebarElements.push({
      title: 'Задачи',
      url: {
        pathname: IntegrationRoutePaths.TASKS,
      },
    });
  }

  if (
    window.ENABLED_FEATURES.includes('integration-scheduler') &&
    user &&
    (await RightsService.hasPermission(user.id, PermissionResource.INTEGRATION_SCHEDULER, PermissionRwAccess.READ))
  ) {
    sidebarElements.push({
      title: 'Планировщик',
      url: {
        pathname: IntegrationRoutePaths.SCHEDULER,
      },
    });
  }

  sidebarElements.push({
    title: 'Статистика',
    url: {
      pathname: IntegrationRoutePaths.STATISTICS,
    },
  });

  registerRoute({
    path: IntegrationRoutePaths.TASKS,
    element: <IntegrationTasksPage />,
    private: true,
  });
  registerRoute({
    path: IntegrationRoutePaths.SCHEDULER,
    element: <IntegrationSchedulerPage />,
    private: true,
  });
  registerRoute({
    path: IntegrationRoutePaths.STATISTICS,
    element: <IntegrationStatisticsPage />,
    private: true,
  });

  registerDefaultSidebarElement({
    title: 'Интеграция',
    childs: sidebarElements,
  });

  IntegrationRunnerService.setDispatch(store.dispatch);
  attachToStore(store);
}
