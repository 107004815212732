import { createReducer } from '@reduxjs/toolkit';
import { clearAuthError, setAccessToken, setAuthenticated, setAuthError, setAuthLoading, setUser } from './actions';
import { IUserMinimalInfo } from '../../packages/auth/models/user';
import { AuthHelper } from './helpers';

export interface IAuthState {
  accessToken: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  userMinimalInfo: IUserMinimalInfo | null;
  error?: string;
}

const initialState: IAuthState = {
  accessToken: AuthHelper.getAccessToken(),
  isLoading: false,
  isAuthenticated: AuthHelper.isAuthenticated(),
  userMinimalInfo: AuthHelper.getUserInfo(),
};

const authReducer = createReducer<IAuthState>(initialState, (builder) =>
  builder
    .addCase(setAccessToken, (state, action) => {
      state.accessToken = action.payload;
      return state;
    })
    .addCase(setAuthenticated, (state, action) => {
      state.isAuthenticated = action.payload;
      if (!action.payload) {
        state.accessToken = null;
        state.userMinimalInfo = null;
      }
      return state;
    })
    .addCase(setUser, (state, action) => {
      state.userMinimalInfo = action.payload;
      return state;
    })
    .addCase(setAuthLoading, (state, action) => {
      state.isLoading = action.payload;
      return state;
    })
    .addCase(setAuthError, (state, action) => {
      state.error = action.payload;
      return state;
    })
    .addCase(clearAuthError, (state) => {
      delete state.error;
      return state;
    })
);

export default authReducer;
