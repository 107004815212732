import { ISidebarElement } from './Sidebar';

export let defaultSidebarElements: ISidebarElement[] = [];
export let adminSidebarElements: ISidebarElement[] = [];

export function registerDefaultSidebarElement(element: ISidebarElement): void {
  defaultSidebarElements.push(element);
}

export function unregisterAllDefaultSidebarElements(): void {
  defaultSidebarElements = [];
}

export function registerAdminSidebarElement(element: ISidebarElement): void {
  adminSidebarElements.push(element);
}

export function unregisterAllAdminSidebarElements(): void {
  adminSidebarElements = [];
}
