import 'moment/locale/ru'; // Must be included once in whole application

import React from 'react';
import moment, { Moment } from 'moment';
import AntDatePicker from 'antd/lib/date-picker';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { datePickerFormat } from '../DatePicker/format';
import { timePickerFormat } from '../TimePicker/format';
import { IClassNameStyleProps } from '../../packages/core/types';

export interface IDatetimePickerProps extends IClassNameStyleProps {
  disabled?: boolean;
  placeholder?: string;
  open?: boolean;
  value?: Date;
  allowClear?: boolean;
  disabledDate?: ((value: Moment) => boolean) | undefined;
  onChange?: (value?: Date) => void;
}

const format = `${datePickerFormat} ${timePickerFormat}`;

export const DatetimePicker: React.FC<IDatetimePickerProps> = ({ value, onChange, ...restProps }) => {
  const handleChange = (value: Moment | null) => {
    onChange?.(value?.toDate());
  };

  return (
    <AntDatePicker
      locale={locale}
      format={format}
      showTime={{
        format: timePickerFormat,
      }}
      value={value == null ? null : moment(value)}
      onChange={handleChange}
      {...restProps}
    />
  );
};
