import { IBaseExecutor, IExecutorMessage } from '../core/types';

export enum OutcomeCommands {
  INTEGRATION_RUN_SCRIPT = '@@integration/run-script',
  INTEGRATION_SCRIPT_ANSWER = '@@integration/script-answer',
}

export enum IncomeCommands {
  INTEGRATION_EXECUTION_STARTED = '@@integration/execution-started',
  INTEGRATION_SCRIPT_MESSAGE = '@@integration/script-message',
  INTEGRATION_SCRIPT_ERROR = '@@integration/script-error',
  INTEGRATION_SCRIPT_DONE = '@@integration/script-done',
}

export interface IBaseExecutorInfoCommand {
  executor: IBaseExecutor;
}

export interface IScriptCommand extends IBaseExecutorInfoCommand {
  message: IExecutorMessage;
}

export interface IScriptErrorCommand extends IBaseExecutorInfoCommand {
  message: string;
  stack?: string;
}

export interface IScriptDoneCommand extends IBaseExecutorInfoCommand {
  message: {
    isSuccess: boolean;
  };
}
