import { ReactComponent as HorizontalSpinnerIcon } from './horizontal.svg';

import React from 'react';
import { SpinnerSize } from './types';

interface IHorizontalLoadingSpinnerProps {
  className?: string;
  size?: SpinnerSize;
}

const Sizes: { [key in SpinnerSize]: any } = {
  small: {
    width: '16px',
    height: '16px',
  },
  default: {
    width: '24px',
    height: '24px',
  },
  large: {
    width: '32px',
    height: '32px',
  },
};

export const HorizontalLoadingSpinner: React.FC<IHorizontalLoadingSpinnerProps> = ({ className, size }) => {
  return <HorizontalSpinnerIcon className={className} {...Sizes[size || 'default']} />;
};
