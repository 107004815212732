import { runScriptRequestAction } from '../store/actions';
import { IRuntimeExecutor, IExecutorHandlers, IRunOptions } from '../core/types';
import { AppDispatch } from '../../../../../store/app';
import { getExecutorHandlers } from '../ws/executor/handlers';

export interface IExecutorInfo {
  executor: IRuntimeExecutor;
  handlers: IExecutorHandlers;
}

export class IntegrationRunnerService {
  private static storeDispatch: AppDispatch;
  private static handlers: IExecutorHandlers;
  private static executors: { [id: string]: IExecutorInfo } = {};

  public static setDispatch(dispatch: AppDispatch): void {
    this.storeDispatch = dispatch;
    this.handlers = getExecutorHandlers(dispatch);
  }

  public static setDefaultHandlers(handlers: IExecutorHandlers): void {
    this.handlers = handlers;
  }

  public static getExecutor(id: string): IRuntimeExecutor | undefined {
    return this.executors[id]?.executor;
  }

  public static registerExecutor(executor: IRuntimeExecutor): IExecutorInfo {
    const executorInfo: IExecutorInfo = {
      executor,
      handlers: this.handlers,
    };
    this.executors[executor.id] = executorInfo;
    return executorInfo;
  }

  public static unregisterExecutor(executorId: number): void {
    delete this.executors[executorId];
  }

  public static getExecutorInfo(executorId: number): IExecutorInfo | null {
    return this.executors[executorId];
  }

  public static async execute(runOptions: IRunOptions): Promise<void> {
    if (!this.storeDispatch) {
      throw new Error('Dispatch must be set up before executing scripts');
    }
    this.storeDispatch(runScriptRequestAction(runOptions));
  }
}
