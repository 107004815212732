import React, { useState, ReactNode, useEffect } from 'react';
import { DatePicker } from '../../../../../../ui-kit/DatePicker';
import { DatetimePicker } from '../../../../../../ui-kit/DatetimePicker';
import { Checkbox } from '../../../../../../ui-kit/inputs/Checkbox';
import { TextInput } from '../../../../../../ui-kit/inputs/TextInput';
import { NumberInput } from '../../../../../../ui-kit/inputs/NumberInput';
import { TimePicker } from '../../../../../../ui-kit/TimePicker';
import Select from '../../../../../../ui-kit/inputs/Select';
import ModalDialog from '../../../../../../ui-kit/ModalDialog';
import { Button } from '../../../../../../ui-kit/Button';

enum DialogElementType {
  STRING = 0,
  DATE = 1,
  TIME = 2,
  DATE_TIME = 3,
  DOUBLE = 4,
  BOOLEAN = 5,
  INT = 6,
}

interface IInputDialogProps {
  onClose: (data: IInputDialogResult) => void;
  onChange: (elementIndex: number, value: ValueType) => void;
}

export const InputDialog: React.FC<IInputDialogInfo & IInputDialogProps> = ({ title, elements, onClose, onChange }) => {
  const [show, setShow] = useState(true);
  const [values, setValues] = useState<{ [index: string]: ValueType }>({});

  useEffect(() => {
    setValues(
      Object.keys(elements).reduce((obj: { [index: string]: ValueType }, key: string) => {
        obj[key] = elements[key].value;
        return obj;
      }, {})
    );
  }, [elements]);

  const handleSubmit = (): void => {
    onClose({
      isSubmitted: true,
      elements: Object.keys(values).reduce((obj: { [index: string]: IInputDialogBaseElement }, key: string) => {
        obj[key] = {
          index: +key,
          value: values[key],
        };
        return obj;
      }, {}),
    });
    setShow(false);
  };

  const handleClose = (): void => {
    setShow(false);
    onClose({
      isSubmitted: false,
    });
  };

  const handleChange = (element: IInputDialogElement, value: ValueType): void => {
    setValues({
      ...values,
      [element.index]: value,
    });
    if (element.isAttachedChangeListener && !element.isReadOnly) {
      onChange(element.index, value);
    }
  };

  const getInput = (element: IInputDialogElement): ReactNode => {
    switch (element.type) {
      case DialogElementType.STRING:
        return (
          <TextInput
            placeholder={element.label}
            readOnly={element.isReadOnly}
            defaultValue={element.value?.toString()}
            onUpdate={(value?: string): void => handleChange(element, value || null)}
          />
        );
      case DialogElementType.DATE:
        return (
          <DatePicker
            className="w-100"
            disabled={element.isReadOnly}
            placeholder={element.label}
            onChange={(date): void => handleChange(element, date || null)}
            value={values[element.index] ? new Date(values[element.index] as string) : undefined}
          />
        );
      case DialogElementType.TIME:
        return (
          <TimePicker
            className="w-100"
            disabled={element.isReadOnly}
            placeholder={element.label}
            onChange={(date): void => handleChange(element, date || null)}
            value={values[element.index] ? new Date(values[element.index] as string) : undefined}
          />
        );
      case DialogElementType.DATE_TIME:
        return (
          <DatetimePicker
            className="w-100"
            disabled={element.isReadOnly}
            placeholder={element.label}
            onChange={(date): void => handleChange(element, date || null)}
            value={values[element.index] ? new Date(values[element.index] as string) : undefined}
          />
        );
      case DialogElementType.BOOLEAN:
        return (
          <div>
            <Checkbox
              className="d-inline-block"
              value={values[element.index] as boolean}
              onChange={(value: boolean): void => handleChange(element, value)}
            />
            <span className="ml-2">{element.label}</span>
          </div>
        );
      case DialogElementType.INT:
        return (
          <NumberInput
            type="integer"
            placeholder={element.label}
            readOnly={element.isReadOnly}
            value={values[element.index] as number | undefined}
            onBlur={(value?: number | string): void => handleChange(element, value || null)}
          />
        );
      case DialogElementType.DOUBLE:
        return (
          <NumberInput
            type="double"
            placeholder={element.label}
            readOnly={element.isReadOnly}
            value={values[element.index] as number | undefined}
            onBlur={(value?: number | string): void => handleChange(element, value || null)}
          />
        );
    }
  };

  const getCombo = (element: IInputDialogComboBoxElement): ReactNode => {
    return (
      <Select
        className="w-100"
        value={values[element.index] as string}
        onChange={(value): void =>
          setValues({
            ...values,
            [element.index]: value,
          })
        }
      >
        {element.availableValues.map((e, i) => (
          <Select.Option key={i} value={e}>
            {e}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const getDialogForm = (): ReactNode => {
    const ids = Object.keys(elements);
    return (
      <form onSubmit={handleSubmit}>
        {ids.map((id, i) => (
          <div key={i} className="mb-2">
            {(elements[id] as IInputDialogComboBoxElement).isComboBox
              ? getCombo(elements[id] as IInputDialogComboBoxElement)
              : getInput(elements[id])}
          </div>
        ))}
      </form>
    );
  };

  const ModalFooter = (
    <div className="d-flex justify-content-end">
      <Button onClick={handleClose}>Отмена</Button>
      <Button type="primary" className="ml-2" onClick={handleSubmit}>
        Принять
      </Button>
    </div>
  );

  return (
    <ModalDialog
      title={title || 'Диалог ввода данных'}
      show={show}
      onCancel={handleClose}
      footer={ModalFooter}
      closeOnOutsideClick={false}
    >
      <div>{getDialogForm()}</div>
    </ModalDialog>
  );
};
