import { AxiosResponse, AxiosHeaders } from 'axios';
import AxiosInstance, { getAuthorizationHeader } from '../../../axios-instance';
import { ILoginStatus } from '../../../store/auth/types';
import { AuthHelper } from '../../../store/auth/helpers';
import { AvailableForSignInUser } from '../models/user';

export class AuthService {
  public static async getAvailableForSignIn(): Promise<AvailableForSignInUser[]> {
    const { data } = await AxiosInstance.get<AvailableForSignInUser[]>('/users');
    return data.sort((a: { login: string }, b: { login: any }) => a.login.localeCompare(b.login));
  }

  public static async signIn(login: string, password: string): Promise<ILoginStatus> {
    return AxiosInstance.post('/auth', { login, password }).then((response: AxiosResponse<ILoginStatus>) => {
      AuthHelper.setAccessToken(response.data.accessToken);
      AuthHelper.setUserInfo(response.data.user);
      const ah = getAuthorizationHeader() as {
        [key: string]: AxiosHeaders | string | string[] | number | boolean | null;
      };
      AxiosInstance.defaults.headers = {
        ...AxiosInstance.defaults.headers,
        ...ah,
      };
      return response.data;
    });
  }

  public static signOut(): void {
    AuthHelper.setAccessToken(null);
    AuthHelper.setUserInfo(null);
  }
}
