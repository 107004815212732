import { IUserMinimalInfo } from '../../packages/auth/models/user';
import { RootState } from '../app';

export const userSelector = (state: RootState): IUserMinimalInfo | null => {
  return state.authentication.userMinimalInfo;
};

export const requiredUserSelector = (state: RootState): IUserMinimalInfo => {
  if (!state.authentication.isAuthenticated || !state.authentication.userMinimalInfo) {
    throw new Error('Для извлечения информации о текущем пользователе необходимо пройти аутентификацию');
  }
  return state.authentication.userMinimalInfo;
};
