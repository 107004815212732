import React from 'react';
import moment, { Moment } from 'moment';
import AntDatePicker from 'antd/lib/date-picker';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { datePickerFormat } from './format';
import { IDatetimePickerProps } from '../DatetimePicker';

type IDatePickerProps = IDatetimePickerProps;

export const DatePicker: React.FC<IDatePickerProps> = ({ value, onChange, ...restProps }) => {
  const handleChange = (value: Moment | null) => {
    onChange?.(value ? value.toDate() : undefined);
  };

  return (
    <AntDatePicker
      locale={locale}
      value={value == null ? null : moment(value)}
      onChange={handleChange}
      format={datePickerFormat}
      {...restProps}
    />
  );
};
