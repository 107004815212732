import React from 'react';
import AntTimePicker from 'antd/lib/time-picker';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { timePickerFormat } from './format';
import { IDatetimePickerProps } from '../DatetimePicker';

type ITimePickerProps = IDatetimePickerProps;

export const TimePicker: React.FC<ITimePickerProps> = ({ value, onChange, ...restProps }) => {
  const handleChange = (value: Moment | null) => {
    onChange?.(value?.toDate());
  };

  return (
    <AntTimePicker
      locale={locale}
      onChange={handleChange}
      format={timePickerFormat}
      value={value == null ? null : moment(value)}
      {...restProps}
    />
  );
};
