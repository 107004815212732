/* eslint-disable react/display-name */
import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/app';
import { BaseRoutesPath } from './base-paths';
import { UserAuthorities } from '../packages/auth/models/user';
import { NotAuthorizedPage } from '../packages/core/pages/NotAuthorizedPage';

type PrivateRouteProps = {
  children?: React.ReactNode;
  requiredAuthorities?: UserAuthorities[];
  props?: any;
} & RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component, requiredAuthorities, ...rest }) => {
  const user = useSelector((state: RootState) => state.authentication.userMinimalInfo);

  if (!Component) {
    return null;
  }

  let routeComponentRenderer;
  if (!user) {
    const redirectAfterLoginUrl =
      window.location.pathname !== BaseRoutesPath.LOGIN ? window.location.pathname + window.location.search : null;

    routeComponentRenderer = (
      <Navigate
        to={{
          pathname: BaseRoutesPath.LOGIN,
          search: redirectAfterLoginUrl ? `redirectTo=${redirectAfterLoginUrl}` : '',
        }}
      />
    );
  } else if (requiredAuthorities && user && !user.authorities.some((a) => requiredAuthorities.includes(a))) {
    routeComponentRenderer = <NotAuthorizedPage />;
  } else if (Component) {
    routeComponentRenderer = Component;
  } else {
    routeComponentRenderer = Component;
  }

  return routeComponentRenderer as JSX.Element;
};

export default PrivateRoute;
