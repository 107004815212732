import { configureStore, Action, AnyAction } from '@reduxjs/toolkit';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { history } from './history';
import { createRootReducer } from './reducers';
import { setupInterceptors } from '../axios-instance';
import integrationTasksMiddleware from '../features/user/integration/tasks/store/middlewares';
// import { routerMiddleware } from 'react-router-redux';
// import { routerMiddleware } from 'connected-react-router';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { useDispatch } from 'react-redux';

const rootReducer = createRootReducer(history);
export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, createRouterMiddleware(history), integrationTasksMiddleware],
});

setupInterceptors(store);

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;

export type AppStore = typeof store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, void, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, void, Action<string>>;

export default store;
