import React from 'react';
import AntCheckbox from 'antd/lib/checkbox';

interface ICheckboxProps {
  id?: number | string;
  value?: boolean;
  label?: React.ReactNode;
  className?: string;
  disabled?: boolean;

  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (value: boolean) => void;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  return (
    <AntCheckbox
      className={props.className}
      checked={props.value}
      disabled={props.disabled}
      onChange={(e) => props.onChange?.(e.target.checked)}
      onClick={props.onClick}
    >
      {props.label}
    </AntCheckbox>
  );
};
