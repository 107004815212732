import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import store from './store/app';
import ConfigProvider from 'antd/lib/config-provider';
import ruRU from 'antd/lib/locale/ru_RU';
import { queryClient } from './queries';
import { ReactQueryDevtools } from 'react-query/devtools';

// ReactDOM.render(
//   <Provider store={store}>
//     <QueryClientProvider client={queryClient}>
//       <ConfigProvider locale={ruRU}>
//         <App />
//       </ConfigProvider>
//       {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
//     </QueryClientProvider>
//   </Provider>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Не удалось найти корневой элемент');

ReactDOMClient.createRoot(rootElement).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </Provider>
);

serviceWorker.unregister();
