import 'animate.css';
import './styles/bootstrap/bootstrap-grid.min.css';
import './styles/antd.less';
import './index.scss';
import './i18n/config';

import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useSelector, useStore } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { MainRouter } from './routes';
import { GuiContainerComponent } from './features/user/integration/tasks/components/GuiContainer';
import { initializeUserFeatures, initializeAdminFeatures } from './features/initializer';
import { userSelector } from './store/auth/selectors';
import { ErrorPage } from './packages/core/pages/ErrorPage';
import LoadingSpinner from './ui-kit/LoadingSpinner';
import { ErrorFallback } from './packages/core/components/ErrorFallback';

export const App: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const store = useStore();
  const [initializeUserFeaturesState, initializeUserFeatureWrapper] = useAsyncFn(initializeUserFeatures);
  const [initializeAdminFeaturesState, initializeAdminFeaturesWrapper] = useAsyncFn(initializeAdminFeatures);

  useEffect(() => {
    const data = { user, store };
    initializeUserFeatureWrapper(data);
    initializeAdminFeaturesWrapper(data);
  }, [user, initializeUserFeatureWrapper, initializeAdminFeaturesWrapper]);

  if (
    (!initializeUserFeaturesState.loading && initializeUserFeaturesState.error) ||
    (!initializeAdminFeaturesState.loading && initializeAdminFeaturesState.error)
  ) {
    return <ErrorPage error={initializeUserFeaturesState.error || initializeAdminFeaturesState.error} />;
  }

  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {initializeUserFeaturesState.loading || initializeAdminFeaturesState.loading ? (
          <LoadingSpinner className="vh-100" text={`${t('loading')}...`} size="large" />
        ) : (
          <React.Fragment>
            <MainRouter />
          </React.Fragment>
        )}
      </ErrorBoundary>
      <GuiContainerComponent />
    </React.Fragment>
  );
};
