/* eslint-disable @typescript-eslint/triple-slash-reference */
// eslint-disable-next-line spaced-comment
/// <reference path="./window.d.ts" />
const host = window.API_SERVER_HOST;
const port = window.API_SERVER_PORT;
const protocol = window.API_USING_SSL ? 'https' : 'http';

export const hostBaseUrl = `${protocol}://${host}:${port}`;
export const apiEndpointUrl = `${hostBaseUrl}/api/v1`;

export const loginWelcomeLabel = window.LOGIN_WELCOME_LABEL;

export const integrationSocketPath = `/api/v1/integration/runner/ws`;

export const integrationDocumentationUrl = 'https://chemsoft.ru/web-integration/docs/';

export const CREATE_TASK_MOD = window.CREATE_TASK_MOD;
