import React from 'react';
import { useRoutes } from 'react-router';
import { UserAuthorities } from '../packages/auth/models/user';
import PrivateRoute from './PrivateRoute';

export type customRoute = {
  path?: string;
  element: React.ReactNode;
  requiredAuthorities?: UserAuthorities[];
  private: boolean;
};

interface IProps {
  pages: customRoute[];
}

function Router({ pages }: IProps): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null {
  const elements = useRoutes(
    pages.map((page) => ({
      path: page.path,
      element: page.private ? (
        <PrivateRoute requiredAuthorities={page.requiredAuthorities} element={page.element} />
      ) : (
        page.element
      ),
    }))
  );

  return elements;
}

export default Router;
