import { AxiosError } from 'axios';
import { translate } from '../../i18n/config';
import { notifications } from './notifications';

const TOKEN_EXPIRATION_API_MESSAGE = 'token_expired';

export function isTokenExpirationError(err: Error): boolean {
  const axiosError = err as AxiosError<Error>;
  if (!axiosError.isAxiosError) {
    return false;
  }

  const { response } = axiosError;
  return response?.status === 401 && response?.data?.message === TOKEN_EXPIRATION_API_MESSAGE;
}

export function getApiErrorMessage(err: Error | null | undefined, defaultMessage: string): string {
  if (!err) {
    return defaultMessage;
  }

  let errorMessage;
  if ((err as AxiosError).isAxiosError) {
    const axiosError = err as AxiosError<Error>;

    if (!axiosError.response) {
      errorMessage = translate('server-not-available');
    } else if (axiosError.response.status === 403) {
      errorMessage = translate('no-permission');
    } else if (isTokenExpirationError(err)) {
      return translate('token-expired');
    } else {
      errorMessage = axiosError.response?.data?.message;
    }
  }

  const finalErrorMessage = errorMessage ?? err.message;
  return defaultMessage + '\n' + finalErrorMessage;
}

export function handleApiError(err: Error | undefined, defaultMessage: string): string {
  console.error(err);

  const errorMessage = getApiErrorMessage(err, defaultMessage);
  notifications.error(errorMessage);
  return errorMessage;
}

export function getHttpStatusFromApiError(err: Error): number | undefined {
  return (err as AxiosError).response?.status;
}
