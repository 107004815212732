import { IBaseTask, TaskVersion } from './core/models/task';
import { ITaskWithStatus, TaskState } from './types';

const baseTaskPrefixes = ['Событие:', 'Скремблер:', 'Блок ', 'ЖРО 2.0'];

export function isSupportedTask(task: IBaseTask): boolean {
  return task.version === TaskVersion.NODE_JS;
  // return task.version === TaskVersion.SECOND;
}

export function isBaseTask(taskName: string): boolean {
  for (const prefix of baseTaskPrefixes) {
    if (taskName.startsWith(prefix)) {
      return true;
    }
  }
  return false;
}

export function toTaskWithStatus(task: IBaseTask): ITaskWithStatus {
  return {
    ...task,
    isScriptFetched: false,
    script: null,
    description: null,
    status: {
      state: TaskState.NONE,
    },
  };
}
