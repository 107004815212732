import React from 'react';
import { UploadFileComponent } from './components/open-file';
import Commands from '../commands';
import { IMeta, AnswerSender } from '../../types';
import { InputDialog } from './components/input-dialog';
import { MessageBoxComponent } from './components/message-box';

function _handleMessage(message: ICommand, answerSender: AnswerSender, meta: IMeta): JSX.Element | undefined {
  if (message.cmd === Commands.OPEN_FILE || message.cmd === Commands.OPEN_FILES) {
    const multiple = message.cmd === Commands.OPEN_FILES;
    return (
      <UploadFileComponent
        meta={meta}
        multiple={multiple}
        onClose={(filePaths: string[] | null): void => {
          answerSender({
            cmd: Commands.OPEN_FILE,
            data: filePaths,
          });
        }}
      />
    );
  } else if (message.cmd === Commands.INPUT_DIALOG) {
    const { data } = message as IInputDialogCommand;
    return (
      <InputDialog
        id={data.id}
        title={data.title}
        elements={data.elements}
        onChange={(elementIndex, value): void => {
          answerSender({
            cmd: Commands.INPUT_DIALOG_EVENT_CHANGE,
            data: {
              index: elementIndex,
              value,
            },
          });
        }}
        onClose={(data): void =>
          answerSender({
            cmd: Commands.INPUT_DIALOG,
            data,
          })
        }
      />
    );
  } else if (message.cmd === Commands.SHOW_MESSAGE_BOX) {
    const { data } = message as IShowMessageBoxCommand;
    return (
      <MessageBoxComponent
        messageData={data}
        onClose={(code): void => {
          answerSender({
            cmd: Commands.SHOW_MESSAGE_BOX,
            data: code,
          });
        }}
      />
    );
  }
}

export default _handleMessage;
