import React from 'react';
import { lazy } from 'react';
import { registerAdminSidebarElement } from '../../../packages/core/components/Sidebar/registry';
import { UserAuthorities } from '../../../packages/auth/models/user';
import { translate } from '../../../i18n/config';
import { registerRoute } from '../../../routes/registry';
import { AttributesConstructorRoutePaths } from './route-paths';

const AttributesConstructorPage = lazy(() => import('./pages/AttributesConstructorPage'));

export function initializeAttributesConstructor(): void {
  registerAdminSidebarElement({
    title: translate('admin.menu.attributes-constructor'),
    url: {
      pathname: AttributesConstructorRoutePaths.CONSTRUCTOR,
    },
  });

  registerRoute({
    path: AttributesConstructorRoutePaths.CONSTRUCTOR,
    requiredAuthorities: [UserAuthorities.ADMIN],
    element: <AttributesConstructorPage />,
    private: true,
  });
}
