import { AnyAction, Dispatch, Middleware, Store } from '@reduxjs/toolkit';
import io from 'socket.io-client';
import { hostBaseUrl, integrationSocketPath } from '../../../../../config';
import createSocketIoMiddleware from '../../../../../store/middlewares/ws/middleware';
import { integrationRunnerSocketHandler } from './handler';
import store, { AppDispatch, AppStore } from '../../../../../store/app';

export const INTEGRATION_SOCKET_CRITERIA = 'integration';

interface IIntegrationSocket {
  socket: SocketIOClient.Socket;
  middleware: Middleware;
  initializeHandlers: (dispatch: AppDispatch) => void;
}

function getIntegrationSocket(): IIntegrationSocket {
  // const accessToken = authState.accessToken;

  const integrationSocket = io(hostBaseUrl, {
    transports: ['websocket', 'polling'],
    path: integrationSocketPath,
    autoConnect: false,
    reconnectionAttempts: 6,
    reconnectionDelay: 4000,
    reconnectionDelayMax: 10000,
    auth: {
      token: '',
    },
  });

  const integrationSocketMiddleware = createSocketIoMiddleware(integrationSocket, INTEGRATION_SOCKET_CRITERIA, ['*']);

  return {
    socket: integrationSocket,
    middleware: integrationSocketMiddleware,
    initializeHandlers: (dispatch: AppDispatch): void => {
      integrationRunnerSocketHandler(integrationSocket, dispatch);
    },
  };
}

const { socket, middleware, initializeHandlers } = getIntegrationSocket();

export function getMiddleware(): Middleware<Record<string, unknown>, any, Dispatch<AnyAction>> {
  return middleware;
}

export function attachToStore(store: Store<any, AnyAction>): void {
  initializeHandlers(store.dispatch);
  let previousToken: string | null = null;
  store.subscribe(() => {
    const authState = store.getState().authentication;
    if (previousToken === authState.accessToken) {
      return;
    }

    if (authState.accessToken) {
      // передаем токен серверу socket io
      (socket.io.opts.auth as any).token = authState.accessToken;
      socket.connect();
    } else {
      delete socket.io.opts.query;
      socket.disconnect();
    }
    previousToken = authState.accessToken;
  });
}
