import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseRoutesPath } from '../../../../routes/base-paths';
import { Button } from '../../../../ui-kit/Button';
import { Result } from '../../../../ui-kit/Result';

interface IErrorProps {
  error?: Error;
  message?: string;
}

export const Error: React.FC<IErrorProps> = ({ error, message }) => {
  const { t } = useTranslation();

  return (
    <Result
      status="error"
      title={t('something-went-wrong')}
      subTitle={<span style={{ whiteSpace: 'pre-line' }}>{message ?? error?.stack ?? error?.message}</span>}
      extra={
        <a href={BaseRoutesPath.HOME}>
          <Button type="primary">{t('return-home')}</Button>
        </a>
      }
    />
  );
};
