import React from 'react';
import { CircleLoadingSpinner } from './CircleLoadingSpinner';
import { HorizontalLoadingSpinner } from './HorizontalLoadingSpinner';
import { SpinnerSize } from './types';
import { CenteredContainer } from '../CenteredContainer';
import { IClassNameStyleProps } from '../../packages/core/types';
import { FlatCircleLoadingSpinner } from './FlatCircleLoadingSpinner';

type SpinnerType = 'circle' | 'flat-circle' | 'horizontal';

const spinners: { [spinner in SpinnerType]: React.ComponentType<{ size?: SpinnerSize }> } = {
  circle: CircleLoadingSpinner,
  'flat-circle': FlatCircleLoadingSpinner,
  horizontal: HorizontalLoadingSpinner,
};

interface ILoadingProps extends IClassNameStyleProps {
  className?: string;
  spinner?: SpinnerType;
  size?: SpinnerSize;
  text?: string;
}

const LoadingSpinner: React.FC<ILoadingProps> = ({ spinner, text, size, ...restProps }) => {
  const SpinnerComponent = spinners[spinner ?? 'circle'];
  return (
    <CenteredContainer {...restProps}>
      <SpinnerComponent size={size} />
      {text && <span>{text}</span>}
    </CenteredContainer>
  );
};

export default LoadingSpinner;
