import { createReducer } from '@reduxjs/toolkit';
import { IIntegrationState, ITaskWithStatus } from '../types';
import { setTasks, addTask, updateTask, deleteTask, addExecutorMessage, removeExecutor } from './actions';

const initialState: IIntegrationState = {
  selectedTaskId: null,
  tasks: [],
  executorsMessages: {},
};

export const integrationReducer = createReducer<IIntegrationState>(initialState, (builder) => {
  builder
    .addCase(setTasks, (state, action) => ({
      ...state,
      tasks: action.payload,
    }))
    .addCase(addTask, (state, action) => ({
      ...state,
      tasks: state.tasks.concat(action.payload),
    }))
    .addCase(updateTask, (state, action) => ({
      ...state,
      tasks: state.tasks.map((e) => {
        if (e.id === action.payload.id) {
          const shallowMerge = {
            ...e,
            ...action.payload,
          };
          if (action.payload.status) {
            shallowMerge.status = {
              ...e.status,
              ...action.payload.status,
            };
          } else {
            shallowMerge.status = e.status;
          }
          return shallowMerge as ITaskWithStatus;
        }
        return e;
      }),
    }))
    .addCase(deleteTask, (state, action) => ({
      ...state,
      tasks: state.tasks.filter((e) => e.id !== action.payload),
    }))
    .addCase(addExecutorMessage, (state, action) => {
      const messageElementId = action.payload.message.data?.id;
      const existingMessages = state.executorsMessages[action.payload.executorId];
      if (existingMessages && existingMessages.length) {
        if (messageElementId != null) {
          /*
           * Поиск по имеющимся командам и замена с аналогичным идентификатором элемента
           * Необходимо для ре-рендеринга уже открытых элементов (например диалог при ивентах)
           */
          for (let i = 0; i < existingMessages.length; i++) {
            if (existingMessages[i].data?.id === messageElementId) {
              existingMessages[i] = action.payload.message;
              return state;
            }
          }
        }
        existingMessages.push(action.payload.message);
      } else {
        state.executorsMessages[action.payload.executorId] = [action.payload.message];
      }
      return state;
    })
    .addCase(removeExecutor, (state, action) => {
      delete state.executorsMessages[action.payload.id];
      return state;
    });
});
