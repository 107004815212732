import AxiosInstance from '../../../axios-instance';
import { PermissionAccessTypeUnion } from '../permission';
import { PermissionResource } from '../permission-resource';

class RightsServiceInternal {
  public async hasPermission(userId: number, resource: PermissionResource, permission: PermissionAccessTypeUnion) {
    const token = sessionStorage.getItem('accessTokenKey');
    try {
      const { data } = await AxiosInstance.get<IPermissionResponse>(
        `/permissions/${PermissionResource[resource]}/${permission}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data.hasPermission;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}

export const RightsService = new RightsServiceInternal();

interface IPermissionResponse {
  hasPermission: boolean;
}
