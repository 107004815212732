export enum TaskVersion {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  NODE_JS = 'NODE_JS',
}

export interface IBaseTask {
  id: number;
  name: string;
  isDone: boolean | null;
  classification: string | null;
  version: TaskVersion;
}

export interface IExtendedTask extends IBaseTask {
  script: string | null;
  description: string | null;
}
