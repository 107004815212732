import React from 'react';
import cn from 'classnames';
import AntButton from 'antd/lib/button';

interface ILoadingButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  type?: 'primary' | 'ghost' | 'text';
  loading?: boolean;
  danger?: boolean;
  disabled?: boolean;
  size?: 'small' | 'middle' | 'large';
  shape?: 'circle' | 'round';
  htmlType?: 'submit' | 'button' | 'reset';
  children?: React.ReactNode;
}

export type ButtonProps = ILoadingButtonProps & React.HTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = (props) => {
  return <AntButton {...props} className={cn(props.className, { 'pointer-events-none': props.disabled })} />;
};
