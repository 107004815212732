/** @enum {Commands} */
const Commands = {
  OPEN_FILE: 'gui@open-file',
  OPEN_FILES: 'gui@open-files',
  INPUT_DIALOG: 'gui@input-dialog',
  INPUT_DIALOG_EVENT_CHANGE: 'gui@input-dialog/event-change',
  SHOW_MESSAGE_BOX: 'gui@message-box/show',
};

module.exports = Commands;
