import React from 'react';
import Spin from 'antd/lib/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { SpinnerSize } from './types';

const indicator = <LoadingOutlined spin />;

interface IFlatCircleLoadingSpinnerProps {
  className?: string;
  style?: React.CSSProperties;
  size?: SpinnerSize;
}

export const FlatCircleLoadingSpinner: React.FC<IFlatCircleLoadingSpinnerProps> = (props) => (
  <Spin indicator={indicator} {...props} />
);
