import notification, { ArgsProps } from 'antd/lib/notification';

class Notifications {
  public success(message: string, duration?: number) {
    notification.success(this.getNotificationData(message, duration));
  }

  public info(message: string, duration?: number) {
    notification.info(this.getNotificationData(message));
  }

  public warning(message: string, duration?: number) {
    notification.warning(this.getNotificationData(message));
  }

  public error(message: string, duration?: number) {
    notification.error(this.getNotificationData(message));
  }

  private getNotificationData(message: string, duration?: number): ArgsProps {
    return {
      message,
      duration: duration ?? 3,
      style: {
        whiteSpace: 'pre-line',
      },
    };
  }
}

export const notifications = new Notifications();
