export enum ResultCode {
  /** Прервать */
  ABORT = 3,
  /** Отмена */
  CANCEL = 2,
  /** Продолжить */
  CONTINUE = 11,
  /** Игнорировать */
  IGNORE = 5,
  /** Да */
  YES = 6,
  /** Нет */
  NO = 7,
  /** Ок */
  OK = 1,
  /** Повтор */
  RETRY = 4,
  /** Повторить */
  TRYAGAIN = 10,
}
