import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BaseRoutesPath } from '../../../../routes/base-paths';
import { Button } from '../../../../ui-kit/Button';
import { Result } from '../../../../ui-kit/Result';
import { CenteredContainer } from '../../../../ui-kit/CenteredContainer';

export const NotAuthorizedPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredContainer className="vh-100">
      <Result
        status="403"
        title="403"
        subTitle={t('http.not-authorized')}
        extra={
          <Link to={BaseRoutesPath.HOME}>
            <Button type="primary">{t('return-home')}</Button>
          </Link>
        }
      />
    </CenteredContainer>
  );
};
