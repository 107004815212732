import * as React from 'react';
import ModalDialog from '../../../../../../ui-kit/ModalDialog';
import { ResultCode } from './types';

interface IProps {
  messageData: IShowMessageData;
  onClose: (code: ResultCode) => void;
}

export const MessageBoxComponent: React.FC<IProps> = ({ messageData, onClose }) => {
  const [show, setShow] = React.useState(true);

  const handleClose = (code: ResultCode): void => {
    setShow(false);
    onClose(code);
  };

  return (
    <ModalDialog
      title={messageData.title || 'Всплывающие сообщение'}
      show={show}
      closeOnOutsideClick={false}
      onOk={() => handleClose(ResultCode.OK)}
      onCancel={(): void => handleClose(ResultCode.CANCEL)}
    >
      <div>
        <span>{messageData.message}</span>
      </div>
    </ModalDialog>
  );
};
