import { IUserMinimalInfo } from '../../packages/auth/models/user';

export class AuthHelper {
  private static accessTokenLocalStorageKey = 'accessTokenKey';
  private static userInfoLocalStorageKey = 'userInfoKey';

  public static isAuthenticated(): boolean {
    return this.getAccessToken() != null && this.getUserInfo() != null;
  }

  public static setAccessToken(token: string | null): void {
    if (!token) {
      sessionStorage.removeItem(this.accessTokenLocalStorageKey);
      // localStorage.removeItem(this.accessTokenLocalStorageKey);
    } else {
      sessionStorage.setItem(this.accessTokenLocalStorageKey, token);
      // localStorage.setItem(this.accessTokenLocalStorageKey, token);
    }
  }

  public static setUserInfo(userInfo: IUserMinimalInfo | null): void {
    if (!userInfo) {
      sessionStorage.removeItem(this.userInfoLocalStorageKey);
      // localStorage.removeItem(this.userInfoLocalStorageKey);
    } else {
      sessionStorage.setItem(this.userInfoLocalStorageKey, JSON.stringify(userInfo));
      // localStorage.setItem(this.userInfoLocalStorageKey, JSON.stringify(userInfo));
    }
  }

  public static getAccessToken(): string | null {
    return sessionStorage.getItem(this.accessTokenLocalStorageKey);
    // return localStorage.getItem(this.accessTokenLocalStorageKey);
  }

  public static getUserInfo(): IUserMinimalInfo | null {
    const userInfoRaw = sessionStorage.getItem(this.userInfoLocalStorageKey);
    // const userInfoRaw = localStorage.getItem(this.userInfoLocalStorageKey);
    if (!userInfoRaw) {
      return null;
    }
    return JSON.parse(userInfoRaw) as IUserMinimalInfo;
  }
}
