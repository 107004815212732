import React from 'react';
import Spin from 'antd/lib/spin';
import { SpinnerSize } from './types';
interface ICircleLoadingSpinnerProps {
  size?: SpinnerSize;
}

export const CircleLoadingSpinner: React.FC<ICircleLoadingSpinnerProps> = ({ size }) => {
  return <Spin size={size} />;
};
