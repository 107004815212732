import { customRoute } from './Router';

export let routesRegistry: customRoute[] = [];

export function registerRoute(routeElement: customRoute): void {
  if (routesRegistry.findIndex((r) => r.path === routeElement.path) > 0) {
    return;
  }
  routesRegistry.push(routeElement);
}

export function unregisterAllRoutes(): void {
  routesRegistry = [];
}
