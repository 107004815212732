import { AnyAction } from '@reduxjs/toolkit';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { apiEndpointUrl } from './config';
import { isTokenExpirationError } from './packages/core/error-handler';
import { AppStore, RootState } from './store/app';
import { clearAuthData } from './store/auth/actions';
import { AuthHelper } from './store/auth/helpers';

interface IAuthorizationHeader {
  Authorization?: string;
}

export function getAuthorizationHeader(): IAuthorizationHeader {
  if (!AuthHelper.isAuthenticated()) {
    return {};
  } else {
    const accessToken = AuthHelper.getAccessToken();
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
}

const ISO_DATE_FORMAT_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;

function dateJsonReviver(key: string, value: any) {
  if (typeof value === 'string' && ISO_DATE_FORMAT_REGEX.test(value)) {
    return new Date(value);
  }
  return value;
}

const AxiosInstance = Axios.create({
  baseURL: apiEndpointUrl,
  headers: {
    ...getAuthorizationHeader(),
  },
  transformResponse: (data) => {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data, dateJsonReviver);
      } catch (e) {
        /* Ignore */
      }
    }
    return data;
  },
});

export function setupInterceptors(store: AppStore): void {
  AxiosInstance.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (err: AxiosError) => {
      if (isTokenExpirationError(err)) {
        (store.dispatch as ThunkDispatch<RootState, void, AnyAction>)(clearAuthData());
      }
      return Promise.reject(err);
    }
  );
}

export default AxiosInstance;
