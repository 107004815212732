import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation from './ru/translation.json';

export const resources = {
  ru: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'ru',
  resources,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function translateWrapper(key: string, options?: any): string {
  return i18n.t(key, options);
}

export const translate: TFunction = translateWrapper;
