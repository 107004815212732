import React from 'react';
import { CenteredContainer } from '../../../../ui-kit/CenteredContainer';
import { Error } from '../../components/Status/Error';

interface IErrorPageProps {
  error?: Error;
  message?: string;
}

export const ErrorPage: React.FC<IErrorPageProps> = (props) => {
  return (
    <CenteredContainer className="vh-100">
      <Error {...props} />
    </CenteredContainer>
  );
};
