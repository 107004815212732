import { AppDispatch } from '../../../../../../store/app';
import { addExecutorMessage, updateTask, removeExecutor } from '../../store/actions';
import { TaskState } from '../../types';
import { IExecutorHandlers } from '../../core/types';
import { IntegrationRunnerService } from '../../services/runner-service';

export function getExecutorHandlers(storeDispatch: AppDispatch): IExecutorHandlers {
  return {
    errorHandler: (err, executor): void => {
      console.error(err);
      storeDispatch(
        updateTask({
          id: executor.runOptions.task.id,
          status: {
            error: err.message,
          },
        })
      );
    },
    messageHandler: (message, executor): void => {
      storeDispatch(
        addExecutorMessage({
          executorId: executor.id,
          message,
        })
      );
    },
    outputHandler: (type, message): void => {
      if (type === 'stdout') {
        console.log(message);
      } else {
        console.error(message);
      }
    },
    doneHandler: (isSuccess, executor): void => {
      storeDispatch(
        updateTask({
          id: executor.runOptions.task.id,
          status: {
            state: isSuccess ? TaskState.DONE : TaskState.FAILED,
          },
        })
      );
      storeDispatch(removeExecutor(executor));
      IntegrationRunnerService.unregisterExecutor(executor.id);
    },
  };
}
