import Select from 'antd/lib/select';

export interface ISelectOptionProps {
  title?: string;
  value: string | number;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  [key: string]: any;
}

export const SelectOption: React.FC<ISelectOptionProps> = Select.Option;
