import { InputRef } from 'antd';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { INumberInputProps } from '.';
import { TextInput } from '../TextInput';

const INTEGER_REGEX = /^[-+]?\d+$/;

function textToInteger(text: string | null | undefined, onSuccess?: (value?: number) => void) {
  if (!onSuccess) {
    return;
  }

  if (!text) {
    onSuccess(undefined);
  } else if (INTEGER_REGEX.test(text)) {
    onSuccess(+text);
  }
}

const IntegerInputForwardRef: ForwardRefRenderFunction<InputRef, INumberInputProps> = (
  { value, onChange, onBlur, ...restProps },
  ref
) => {
  const handleChange = (text?: string | null) => {
    textToInteger(text, onChange);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    textToInteger(text, onBlur);
  };

  return (
    <TextInput ref={ref} value={value?.toString() ?? ''} onChange={handleChange} onBlur={handleBlur} {...restProps} />
  );
};

export const IntegerInput = forwardRef<InputRef, INumberInputProps>(IntegerInputForwardRef);
