import './styles.scss';

import React, { CSSProperties } from 'react';
import cn from 'classnames';
import AntModal from 'antd/lib/modal';
import { Button } from '../Button';
import { ButtonProps } from 'antd/lib/button/button';

export interface IModalDialogProps {
  className?: string;
  style?: CSSProperties;
  show?: boolean;
  title?: React.ReactNode;
  centered?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  confirmLoading?: boolean;
  closeOnOutsideClick?: boolean;
  footer?: React.ReactNode | null;
  width?: string | number;
  children?: React.ReactNode;
}

const ModalDialog: React.FC<IModalDialogProps> = ({ className, show, title, closeOnOutsideClick, ...restProps }) => {
  return (
    <AntModal
      className={cn('chemic-modal', className)}
      open={show}
      title={title}
      destroyOnClose={true}
      maskClosable={closeOnOutsideClick}
      {...restProps}
    >
      {restProps.children}
    </AntModal>
  );
};

interface IModalDialogFunctionArgs {
  className?: string;
  visible?: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
  onOk?: () => any;
  onCancel?: () => any;
  centered?: boolean;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  icon?: React.ReactNode;
  zIndex?: number;
  style?: React.CSSProperties;
  autoFocusButton?: null | 'ok' | 'cancel';
  bodyStyle?: React.CSSProperties;
  width?: string | number;
}

type ModalDialogFunc = (props: IModalDialogFunctionArgs) => void;

interface IModalStaticFunctions {
  info: ModalDialogFunc;
  confirm: ModalDialogFunc;
}

type ExtendedModalType = typeof ModalDialog & IModalStaticFunctions;

const ExtendedModal = ModalDialog as ExtendedModalType;
ExtendedModal.info = AntModal.info as ModalDialogFunc;
ExtendedModal.confirm = AntModal.confirm as ModalDialogFunc;

export default ExtendedModal;
