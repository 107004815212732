import './styles.scss';

import React, { forwardRef, ForwardRefRenderFunction, Fragment } from 'react';
import { IClassNameStyleProps } from '../../../packages/core/types';
import { DoubleInput } from './DoubleInput';
import { IntegerInput } from './IntegerInput';
import { InputRef } from 'antd';

export interface INumberInputProps extends IClassNameStyleProps {
  autoComplete?: string;
  autoFocus?: boolean;
  value?: number;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: (value?: number | string) => void;
  onBlur?: (value?: number | string) => void;
}
interface ITypedNumberInputProps extends INumberInputProps {
  type?: 'integer' | 'double';
}

const NumberInputForwardRef: ForwardRefRenderFunction<InputRef, ITypedNumberInputProps> = (
  { type, ...restProps },
  ref
) => {
  return (
    <Fragment>
      {type === 'integer' ? <IntegerInput ref={ref} {...restProps} /> : <DoubleInput ref={ref} {...restProps} />}
    </Fragment>
  );
};

export const NumberInput = forwardRef<InputRef, ITypedNumberInputProps>(NumberInputForwardRef);
