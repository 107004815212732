import React from 'react';
import AntSelect, { SelectValue as AntSelectValue, SelectProps, RefSelectProps } from 'antd/lib/select';
import // OptionsType as RcOptionsType,
// OptionData as RcOptionData,
// OptionGroupData as RcOptionGroupData,
'rc-select/';
import { OptGroup as RcOptionGroupData } from 'rc-select/';
import { Option as RcOptionData } from 'rc-select/';
import RcOptionsType from 'rc-select/';
import { SelectOption } from './Option';

export type SelectValue = AntSelectValue;
export type OptionData = typeof RcOptionData;
export type OptionGroupData = typeof RcOptionGroupData;
export type OptionsType = (OptionData | OptionGroupData)[];

declare const AntSelectComponent: <VT extends SelectValue = SelectValue>(
  props: SelectProps<VT> & {
    ref?: React.Ref<RefSelectProps> | undefined;
  }
) => React.ReactElement;

type SelectComponentInterface = typeof AntSelectComponent;
interface ISelectInterface extends SelectComponentInterface {
  Option: typeof SelectOption;
}

const Select: ISelectInterface = AntSelect as ISelectInterface;
Select.Option = SelectOption;

export default Select;
