import React from 'react';
import { NotFoundedPage } from '../../pages/NotFoundedPage';
import { ErrorPage } from '../../pages/ErrorPage';
import { AxiosError } from 'axios';

export enum ErrorType {
  NOT_FOUND,
  AUTHORIZATION,
  UNKNOWN,
}

export function getErrorType(error?: Error): ErrorType {
  console.error(error);
  if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError;

    if (axiosError.code === '404') {
      return ErrorType.NOT_FOUND;
    } else if (axiosError.code === '403') {
      return ErrorType.AUTHORIZATION;
    }
  }
  return ErrorType.UNKNOWN;
}

interface IErrorFallbackProps {
  error?: Error;
  stack?: string;
  resetErrorBoundary: () => void;
}

export const ErrorFallback: React.FC<IErrorFallbackProps> = ({ error }) => {
  const getComponent = (): React.ReactElement => {
    const type = getErrorType(error);
    switch (type) {
      case ErrorType.NOT_FOUND:
        return <NotFoundedPage />;
      case ErrorType.AUTHORIZATION:
      case ErrorType.UNKNOWN:
      default:
        return <ErrorPage />;
    }
  };

  return getComponent();
};
