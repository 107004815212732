import React, { lazy, Suspense } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { NotFoundedPage } from '../packages/core/pages/NotFoundedPage';
import { history } from '../store/history';
import { BaseRoutesPath } from './base-paths';
import { SuspenseFallback } from './SuspenseFallback';
import { routesRegistry } from './registry';
import { AdminRoutesPath } from './admin-paths';
import { UserAuthorities } from '../packages/auth/models/user';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { parse, stringify } from 'query-string';
import Router from './Router';

const HomePage = lazy(() => import('../packages/core/pages/HomePage'));
const LoginPage = lazy(() => import('../packages/auth/pages/LoginPage'));
const AdminHomePage = lazy(() => import('../packages/core/pages/AdminHomePage'));
// const HelpPage = lazy(() => import('../packages/core/pages/HelpPage'));

export const MainRouter: React.FC = () => {
  const res = [...routesRegistry, ...pagesArray];
  return (
    <ReduxRouter history={history}>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <Suspense fallback={<SuspenseFallback />}>
          <Router pages={res} />
        </Suspense>
      </QueryParamProvider>
    </ReduxRouter>
  );
};

const pagesArray = [
  {
    path: BaseRoutesPath.HOME,
    element: <HomePage />,
    private: true,
  },
  {
    path: BaseRoutesPath.LOGIN,
    element: <LoginPage />,
    private: false,
  },
  // {
  //   path: BaseRoutesPath.HELP,
  //   element: <HelpPage />,
  //   private: true,
  // },
  {
    path: AdminRoutesPath.HOME,
    element: <AdminHomePage />,
    requiredAuthorities: [UserAuthorities.ADMIN],
    private: true,
  },
  {
    path: '*',
    element: <NotFoundedPage />,
    private: false,
  },
];
