import { Dispatch, Middleware, AnyAction } from '@reduxjs/toolkit';
import { Manager } from 'socket.io-client';
import socketIoWildcard from 'socketio-wildcard';

type EmitFunction = (event: string, data?: any) => void;
type ExecuteFunction = (
  action: AnyAction,
  emit: EmitFunction,
  next: Dispatch<AnyAction>,
  dispatch?: Dispatch<AnyAction>
) => AnyAction;

interface ISocketMiddlewareOptions {
  execute?: ExecuteFunction;
  handlersSetup?: (socket: SocketIOClient.Socket, dispatch: Dispatch<AnyAction>) => void;
}

/**
 * Создает middleware для socket.io клиента
 * @param socket Экземпляр сокета
 * @param criteria Критерий для выбора экшнов, которые будут обработаны (префикс, массив префиксов, функция)
 * @param incomeEventNames Имена входящих событий, которые будут обрабатываться
 * @param options Дополнительные опции
 * @return Объект middleware
 */
export default function createSocketIoMiddleware(
  socket: SocketIOClient.Socket,
  criteria: string | string[] | ((action: AnyAction) => boolean),
  incomeEventNames: string[] | ['*'],
  options: ISocketMiddlewareOptions = {}
): Middleware {
  function removeCriteria(actionType: string): string {
    const slashIndex = actionType.indexOf('/');
    if (slashIndex !== -1) {
      return actionType.substr(slashIndex + 1);
    }
    return actionType;
  }

  function defaultExecute(action: AnyAction, emit: EmitFunction, next: Dispatch<AnyAction>): AnyAction {
    emit(removeCriteria(action.type), action.payload);
    return next(action);
  }

  function evaluate(action: AnyAction, option: ((action: AnyAction) => boolean) | string | string[]): boolean {
    if (!action || !action.type) {
      return false;
    }

    const { type } = action;
    let matched = false;
    if (typeof option === 'function') {
      // Test function
      matched = option(action);
    } else if (typeof option === 'string') {
      // String prefix
      matched = type.indexOf(option) === 0;
    } else if (Array.isArray(option)) {
      // Array of types
      matched = option.some((item) => type.indexOf(item) === 0);
    }

    return matched;
  }

  const executeFunction = options.execute || defaultExecute;
  const emitBound = socket.emit.bind(socket);

  return ({ dispatch }) => {
    // Wire socket.io to dispatch actions sent by the server.
    if (incomeEventNames.length && incomeEventNames[0] === '*') {
      const path = socketIoWildcard(Manager);
      path(socket);
    }
    incomeEventNames.forEach((eventName) => {
      socket.on(eventName, (data: any) =>
        dispatch({
          type: eventName,
          payload: data,
        })
      );
    });

    return (next) => (action: AnyAction): AnyAction => {
      if (evaluate(action, criteria + '/')) {
        return executeFunction(action, emitBound, next, dispatch);
      }
      return next(action);
    };
  };
}

/**
 * Формирует имя экшна, пригодное для использование в middleware
 * @param criteria Критерий
 * @param name Оригинальное имя
 * @returns Модифицированное имя
 */
export function wsAction(criteria: string, name: string): string {
  return criteria + '/' + name;
}
