import React from 'react';
import { CenteredContainer } from '../../../../ui-kit/CenteredContainer';
import { NotFound } from '../../components/Status/NotFound';

export const NotFoundedPage: React.FC = () => {
  return (
    <CenteredContainer className="vh-100">
      <NotFound />
    </CenteredContainer>
  );
};
