export enum UserAuthorities {
  ADMIN = 'ADMIN',
}

interface IUserBase {
  id: number;
  login: string;
  fullName: string;
  position?: string;
  imageUrl: string;
}

export type AvailableForSignInUser = IUserBase;

export interface IUserMinimalInfo extends IUserBase {
  companyLevelBindingIdentifiers: number[];
  authorities: UserAuthorities[];
}

export interface IUser extends IUserMinimalInfo {
  login: string;
  password?: string;
  comment?: string;
  creationDate?: Date;
}
