export enum PermissionSimpleAccess {
  PERMIT = 'PERMIT',
}

export enum PermissionRwAccess {
  READ = 'READ',
  WRITE = 'READ_WRITE',
}

export type PermissionAccessTypeUnion = PermissionSimpleAccess | PermissionRwAccess;
