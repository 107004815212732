import React from 'react';
import cn from 'classnames';
import { IClassNameStyleProps } from '../../packages/core/types';

type ICenteredContainerProps = IClassNameStyleProps;

export const CenteredContainer: React.FC<React.PropsWithChildren<ICenteredContainerProps>> = ({
  className,
  style,
  children,
}) => {
  return (
    <div
      className={cn(className, 'd-flex', 'flex-column', 'align-items-center', 'justify-content-center')}
      style={style}
    >
      {children}
    </div>
  );
};
