import React, { CSSProperties, useState } from 'react';
import UploadIcon from '@ant-design/icons/InboxOutlined';
import { IMeta } from '../../../../types';
import ModalDialog from '../../../../../../ui-kit/ModalDialog';
import Upload, { UploadChangeParam, UploadFile } from '../../../../../../ui-kit/Upload';

const uploadIconStyle: CSSProperties = {
  fontSize: '48px',
};

interface IProps {
  onClose: (filePaths: string[] | null) => void;
  multiple?: boolean;
  meta: IMeta;
}

export const UploadFileComponent: React.FC<IProps> = ({ onClose, multiple }) => {
  const [show, setShow] = useState(true);
  const [files, setFiles] = useState<UploadFile[]>([]);

  const handleClose = (): void => {
    setShow(false);
    onClose(null);
  };

  const handleSubmit = () => {
    console.log(files);
    // TODO Upload files to server and close modal
  };

  const handleChange = (event: UploadChangeParam) => {
    setFiles(event.fileList);
  };

  return (
    <ModalDialog show={show} title="Загрузка файлов" onOk={handleSubmit} onCancel={handleClose}>
      <Upload.Dragger
        multiple={multiple}
        maxCount={multiple ? 20 : 1}
        beforeUpload={() => false}
        fileList={files}
        onChange={handleChange}
      >
        <div className="mb-3">
          <UploadIcon style={uploadIconStyle} />
        </div>
        <p className="ant-upload-text">Выберите или переместите файл в данную зону для загрузки</p>
      </Upload.Dragger>
    </ModalDialog>
  );
};
