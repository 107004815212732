import { OmitId } from '../../../../../packages/core/types';
import AxiosInstance from '../../../../../axios-instance';
import { IBaseTask } from '../core/models/task';

export class IntegrationTasksService {
  public static async getAll(): Promise<IBaseTask[]> {
    const response = await AxiosInstance.get('/integration/tasks');
    return response.data;
  }

  public static async update(task: IBaseTask): Promise<IBaseTask> {
    const response = await AxiosInstance.put<IBaseTask>(`/integration/tasks/${task.id}`, {
      id: task.id,
      classification: task.classification,
      isDone: task.isDone,
      name: task.name,
      version: task.version,
    } as IBaseTask);
    return response.data;
  }

  public static async updateScript(id: number, newScript: string | null): Promise<void> {
    await AxiosInstance.put(`/integration/tasks/${id}/script`, {
      script: newScript,
    });
  }

  public static async add(task: OmitId<IBaseTask>): Promise<IBaseTask> {
    const response = await AxiosInstance.post(`/integration/tasks`, task);
    return response.data;
  }

  public static async delete(id: number): Promise<void> {
    await AxiosInstance.delete(`/integration/tasks/${id}`);
  }

  public static async getScript(taskId: number): Promise<string> {
    const response = await AxiosInstance.get(`/integration/tasks/${taskId}/script`);
    return response.data ? response.data.toString() : response.data;
  }

  public static async getDescription(taskId: number): Promise<string> {
    const response = await AxiosInstance.get(`/integration/tasks/${taskId}/description`);
    return response.data;
  }

  public static async getAvailableClasses(): Promise<string[]> {
    const response = await AxiosInstance.get('/integration/tasks/classes');
    return response.data;
  }
}
