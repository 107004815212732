import { createAction, DeepPartial } from '@reduxjs/toolkit';
import { wsAction } from '../../../../../store/middlewares/ws/middleware';
import { OutcomeCommands, IScriptCommand } from '../ws/commands';
import { INTEGRATION_SOCKET_CRITERIA } from '../ws';
import { IRunOptions, IBaseExecutor } from '../core/types';
import { RequireId } from '../../../../../packages/core/types';
import { AppDispatch, AppThunk, AppThunkDispatch } from '../../../../../store/app';
import { createStatusActionsAndSelectors, RequestType } from '../../../../../store/status/helpers';
import { IntegrationTasksService } from '../services/tasks-service';
import { getApiErrorMessage, handleApiError } from '../../../../../packages/core/error-handler';
import { IBaseTask, TaskVersion } from '../core/models/task';
import { IntegrationRunnerService } from '../services/runner-service';
import { IUserMinimalInfo } from '../../../../../packages/auth/models/user';
import { ITaskWithStatus, IExecutorAttachedMessage, TaskState } from '../types';
import { toTaskWithStatus } from '../utils';

import { notifications } from '../../../../../packages/core/notifications';
export const runScriptRequestAction = createAction<IRunOptions>(
  wsAction(INTEGRATION_SOCKET_CRITERIA, OutcomeCommands.INTEGRATION_RUN_SCRIPT)
);
export const scriptAnswerRequestAction = createAction<IScriptCommand>(
  wsAction(INTEGRATION_SOCKET_CRITERIA, OutcomeCommands.INTEGRATION_SCRIPT_ANSWER)
);

export const taskStatusActions = createStatusActionsAndSelectors('@@integration-tasks/status');
export const taskScriptStatusActions = createStatusActionsAndSelectors('@@integration-tasks/script/status');
export const taskDescriptionStatusActions = createStatusActionsAndSelectors('@@integration-tasks/description/status');

export const setTasks = createAction<ITaskWithStatus[]>('@@integration-tasks/setTasks');
export const addTask = createAction<ITaskWithStatus>('@@integration-tasks/addTask');
export const updateTask = createAction<RequireId<DeepPartial<ITaskWithStatus>>>('@@integration-tasks/updateTask');
export const deleteTask = createAction<number>('@@integration-tasks/deleteTask');

export const addExecutorMessage = createAction<IExecutorAttachedMessage>('@@integration/addExecutorMessage');
export const removeExecutor = createAction<IBaseExecutor>('@@integration/removeExecutor');

const tasksCacheTtlMs = 1000 * 15;
const lastFetchedTasks: { tasks?: ITaskWithStatus[]; fetchTime?: Date } = {};

export const getTasksThunk = (): AppThunk => async (dispatch: AppDispatch) => {
  const currentTime = new Date();
  if (lastFetchedTasks.fetchTime && currentTime.getTime() - lastFetchedTasks.fetchTime.getTime() < tasksCacheTtlMs) {
    return;
  }

  try {
    dispatch(taskStatusActions.actions.request(RequestType.FETCH));

    const tasks = await IntegrationTasksService.getAll();
    lastFetchedTasks.tasks = tasks.map(toTaskWithStatus);
    lastFetchedTasks.fetchTime = new Date();
    dispatch(setTasks(lastFetchedTasks.tasks));

    dispatch(taskStatusActions.actions.success(RequestType.FETCH));
  } catch (err: any) {
    const message = handleApiError(err, 'Не удалось получить список задач');
    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.FETCH,
        error: message,
      })
    );
  }
};

export const updateTaskThunk = (id: number, task: IBaseTask): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(taskStatusActions.actions.request(RequestType.UPDATE));
    const updatedTask = await IntegrationTasksService.update(task);
    dispatch(updateTask(updatedTask));
    dispatch(taskStatusActions.actions.success(RequestType.UPDATE));
  } catch (err: any) {
    const message = handleApiError(err, 'Не удалось обновить задачу');
    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.UPDATE,
        error: message,
      })
    );
  }
};

export const updateTaskScriptThunk = (id: number, newScript: string | null): AppThunk => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch(taskScriptStatusActions.actions.request(RequestType.PARTIAL_UPDATE));
    await IntegrationTasksService.updateScript(id, newScript);
    dispatch(
      updateTask({
        id,
        script: newScript,
      })
    );
    dispatch(taskScriptStatusActions.actions.success(RequestType.PARTIAL_UPDATE));
  } catch (err: any) {
    const message = handleApiError(err, 'Не удалось обновить скрипт задачи');
    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.PARTIAL_UPDATE,
        error: message,
      })
    );
  }
};

export const addTaskThunk = (tasks: IBaseTask[], name: string): AppThunk<Promise<ITaskWithStatus>> => async (
  dispatch: AppDispatch
) => {
  const isTaskWithSameNameExists = tasks.find((t) => t.name === name) != null;
  if (isTaskWithSameNameExists) {
    const error = `Задача с именем "${name}" уже существует`;
    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.ADD,
        error,
      })
    );
    throw new Error(error);
  }

  try {
    dispatch(taskStatusActions.actions.request(RequestType.ADD));
    const task = await IntegrationTasksService.add({
      name,
      classification: null,
      isDone: false,
      // version: TaskVersion.SECOND,
      version: TaskVersion.NODE_JS,
    });
    const taskWithStatus = {
      ...task,
      status: {
        state: TaskState.NONE,
      },
    } as ITaskWithStatus;
    dispatch(addTask(taskWithStatus));
    dispatch(taskStatusActions.actions.success(RequestType.ADD));

    return taskWithStatus;
  } catch (err: any) {
    const error = getApiErrorMessage(err, 'Не удалось добавить задачу');

    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.ADD,
        error,
      })
    );
    throw new Error(error);
  }
};

export const deleteTaskThunk = (taskId: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(taskStatusActions.actions.request(RequestType.DELETE));
    await IntegrationTasksService.delete(taskId);
    dispatch(deleteTask(taskId));
    dispatch(taskStatusActions.actions.success(RequestType.DELETE));
  } catch (err: any) {
    const message = handleApiError(err, 'Не удалось удалить задачу');
    dispatch(
      taskStatusActions.actions.failure({
        requestType: RequestType.DELETE,
        error: message,
      })
    );
  }
};

export const fetchTaskDescriptionThunk = (taskId: number): AppThunk => async (dispatch: AppDispatch) => {
  try {
    dispatch(taskDescriptionStatusActions.actions.request(RequestType.FETCH));
    const description = await IntegrationTasksService.getDescription(taskId);
    dispatch(
      updateTask({
        id: taskId,
        description,
      })
    );
    dispatch(taskDescriptionStatusActions.actions.success(RequestType.FETCH));
  } catch (err: any) {
    const message = handleApiError(err, 'Не удалось получить описание задачи');
    dispatch(
      taskDescriptionStatusActions.actions.failure({
        requestType: RequestType.FETCH,
        error: message,
      })
    );
  }
};

const fetchTaskScriptInternal = (task: ITaskWithStatus): AppThunk<Promise<ITaskWithStatus>> => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch(taskScriptStatusActions.actions.request(RequestType.FETCH));
    const script = await IntegrationTasksService.getScript(task.id);
    const updatedTask: ITaskWithStatus = {
      ...task,
      script,
      isScriptFetched: true,
    };
    dispatch(updateTask(updatedTask));
    dispatch(taskScriptStatusActions.actions.success(RequestType.FETCH));

    return updatedTask;
  } catch (err: any) {
    const message = getApiErrorMessage(err, 'Не удалось получить скрипт задачи');
    dispatch(
      taskScriptStatusActions.actions.failure({
        requestType: RequestType.FETCH,
        error: message,
      })
    );
    throw err;
  }
};

export const fetchTaskScript = (task: ITaskWithStatus): AppThunk => async (dispatch: AppThunkDispatch) => {
  try {
    await dispatch(fetchTaskScriptInternal(task));
  } catch (err: any) {
    notifications.error(err.message);
  }
};

export const executeTask = (task: ITaskWithStatus, user: IUserMinimalInfo): AppThunk => async (
  dispatch: AppThunkDispatch
) => {
  let usedTask = task;
  try {
    if (!task.isScriptFetched) {
      usedTask = await dispatch(fetchTaskScriptInternal(task));
    }

    if (!usedTask.script) {
      dispatch(
        updateTask({
          id: usedTask.id,
          status: {
            state: TaskState.FAILED,
            error: 'Скрипт пуст',
          },
        })
      );
      return;
    }

    IntegrationRunnerService.execute({
      task: {
        id: usedTask.id,
        name: usedTask.name,
        script: usedTask.script,
      },
      user: {
        id: user.id,
        login: user.login,
      },
      launchedAs: 'task',
    });

    dispatch(
      updateTask({
        id: usedTask.id,
        status: {
          state: TaskState.EXECUTING,
          error: undefined,
        },
      })
    );
  } catch (err: any) {
    dispatch(
      updateTask({
        id: usedTask.id,
        status: {
          state: TaskState.FAILED,
          error: err.message,
        },
      })
    );
  }
};
