import {
  IncomeCommands,
  IBaseExecutorInfoCommand,
  IScriptCommand,
  IScriptErrorCommand,
  IScriptDoneCommand,
} from './commands';
import { IntegrationRunnerService, IExecutorInfo } from '../services/runner-service';
import { scriptAnswerRequestAction } from '../store/actions';
import { AppDispatch } from '../../../../../store/app';
import { IBaseExecutor, IRuntimeExecutor } from '../core/types';

function registerExecutor(baseExecutor: IBaseExecutor, dispatch: AppDispatch): IExecutorInfo {
  const executor: IRuntimeExecutor = {
    ...baseExecutor,
    send: (message) => {
      dispatch(
        scriptAnswerRequestAction({
          executor: baseExecutor,
          message,
        })
      );
    },
  };
  const executorInfo = IntegrationRunnerService.registerExecutor(executor);
  return executorInfo;
}

function getExecutorOrRegisterIfNotExist(baseExecutor: IBaseExecutor, dispatch: AppDispatch): IExecutorInfo {
  const executorInfo = IntegrationRunnerService.getExecutorInfo(baseExecutor.id);
  if (!executorInfo) {
    return registerExecutor(baseExecutor, dispatch);
  }
  return executorInfo;
}

function handleExecutionStartedCommand(dispatch: AppDispatch, command: IBaseExecutorInfoCommand): void {
  registerExecutor(command.executor, dispatch);
}

function handleExecutorMessage(dispatch: AppDispatch, command: IScriptCommand): void {
  const executorInfo = getExecutorOrRegisterIfNotExist(command.executor, dispatch);
  const executorCommand = command.message;
  if (executorCommand.cmd === 'stdout' || executorCommand.cmd === 'stderr') {
    if (executorInfo.handlers.outputHandler) {
      executorInfo.handlers.outputHandler(executorCommand.cmd, executorCommand.data as string, executorInfo.executor);
    }
  } else {
    executorInfo.handlers.messageHandler(executorCommand, executorInfo.executor);
  }
}

function handleExecutorError(dispatch: AppDispatch, command: IScriptErrorCommand): void {
  const executorInfo = getExecutorOrRegisterIfNotExist(command.executor, dispatch);

  const error = new Error(command.message);
  error.stack = command.stack;

  executorInfo.handlers.errorHandler(error, executorInfo.executor);
}

function handleExecutorDone(dispatch: AppDispatch, command: IScriptDoneCommand): void {
  const executorInfo = getExecutorOrRegisterIfNotExist(command.executor, dispatch);
  if (executorInfo.handlers.doneHandler) {
    executorInfo.handlers.doneHandler(command.message.isSuccess, executorInfo.executor);
  }
}

export function integrationRunnerSocketHandler(socket: SocketIOClient.Socket, dispatch: AppDispatch): void {
  socket.on(IncomeCommands.INTEGRATION_EXECUTION_STARTED, (data: IBaseExecutorInfoCommand) => {
    handleExecutionStartedCommand(dispatch, data);
  });
  socket.on(IncomeCommands.INTEGRATION_SCRIPT_MESSAGE, (data: IScriptCommand) => {
    handleExecutorMessage(dispatch, data);
  });
  socket.on(IncomeCommands.INTEGRATION_SCRIPT_ERROR, (data: IScriptErrorCommand) => {
    handleExecutorError(dispatch, data);
  });
  socket.on(IncomeCommands.INTEGRATION_SCRIPT_DONE, (data: IScriptDoneCommand) => {
    handleExecutorDone(dispatch, data);
  });
}
